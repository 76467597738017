@font-face {
  font-family: "MullerRegular";
  font-weight: normal;
  src: url("fonts/MullerRegular.ttf");
  font-display: swap;
}

html,
body,
header,
main,
footer,
ul,
li,
button,
input,
textarea,
h1,
h2,
h3,
h4,
h5,
h6,
address {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  :focus {
    outline: none;
  }
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
}

li {
  list-style-type: none;
}

button {
  border: none;
  color: inherit;
  outline: none;
}

body {
  font-family: "MullerRegular", sans-serif;
}
