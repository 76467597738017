.contact-form {
  width: 90%;
  max-width: 1000px;
  margin-top: 40px;
  &_inner {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    position: relative;
  }
  &_input {
    cursor: pointer;
    width: 48%;
    height: 80px;
    border-radius: 40px;
    padding-left: 22px;
    box-sizing: border-box;
    border: none;
    font-size: 21px;
    font-family: "MullerRegular", sans-serif !important;
  }
  &_button {
    width: 100%;
    background-color: black;
    font-size: 25px;
    color: white;
    height: 80px;
    border-radius: 50px;
    text-transform: uppercase;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    cursor: pointer;
    &:hover {
      border: 3px solid white;
    }
  }
}

@media screen and (max-width: 940px) {
  .contact-form {
    width: 100%;
  }
}

@media screen and (max-width: 615px) {
  .contact-form {
    width: 100%;
    &_inner {
      flex-direction: column;
      margin-bottom: 5px;
    }
    &_input {
      width: 100%;
      font-size: 13px;
      height: 50px;
      border-radius: 25px;
      padding-left: 10px;
      margin-bottom: 25px;
    }
    &_button {
      height: 50px;
      font-size: 20px;
    }
  }
}

@media screen and (min-width: 2200px) {
  .contact_decoration.test {
    margin-left: 20%;
  }
}

.error-span-left {
  position: absolute;
  top: -20px;
  color: red;
  opacity: 0;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
}

.error-span-right {
  position: absolute;
  top: -20px;
  color: red;
  left: 52%;
  opacity: 0;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
}

.active {
  opacity: 1;
}

.input-tel {
  background-color: white;
  width: 48%;
  height: 80px;
  border-radius: 50px;
  padding-left: 20px;
  box-sizing: border-box;
  font-family: "MullerRegular", sans-serif !important;
  cursor: pointer;
}

.PhoneInputInput {
  font-size: 21px;
  border: none;
  margin-right: 10px;
  font-family: "MullerRegular", sans-serif !important;
  cursor: pointer;
}

@media screen and (max-width: 615px) {
  .input-tel {
    width: 100%;
    height: 50px;
    border-radius: 25px;
    padding-left: 10px;
    margin-bottom: 20px;
  }
  .PhoneInputInput {
    font-size: 13px;
  }
  .error-span-right {
    position: absolute;
    top: 55px;
    left: 0;
  }
}

.css-2b097c-container {
  width: 48%;
}

.react-select__control {
  background-color: white;
  width: 100%;
  min-height: 80px !important;
  border-radius: 40px !important;
  padding-left: 20px;
  box-sizing: border-box;
  cursor: pointer !important;
}

.react-select__value-container {
  font-size: 21px;
  font-family: "MullerRegular", sans-serif !important;
  padding-top: 8px !important;
}

.react-select__single-value {
  font-family: "MullerRegular", sans-serif !important;
}

.react-select__value-container {
  font-size: 21px;
  color: #707480 !important;
  font-family: "MullerRegular", sans-serif !important;
}

.react-select__menu {
  border-radius: 20px !important;
  font-family: "MullerRegular", sans-serif !important;
}

.react-select__menu-list {
  border-radius: 20px !important;
  font-family: "MullerRegular", sans-serif !important;
}

.react-select__option {
  cursor: pointer !important;
  font-size: 20px !important;
  font-family: "MullerRegular", sans-serif !important;
}

@media screen and (max-width: 615px) {
  .css-2b097c-container {
    width: 100%;
  }
  .react-select__control {
    min-height: 50px !important;
    margin-bottom: 20px;
    padding-left: 0;
  }
  .react-select__value-container {
    font-size: 14px;
  }
  .messenger-id {
    margin-top: 5px;
    margin-bottom: 20px;
  }
  .traffic-input {
    margin-top: 5px;
  }
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

@media screen and (max-width: 615px) {
  .react-select__value-container {
    font-size: 13px !important;
  }
  .react-select__placeholder {
    font-size: 13px !important;
  }
  .react-select__option {
    font-size: 13px !important;
  }
}

.submit-window {
  width: 100%;
  max-width: 1000px;
  margin-top: 40px;
  height: 440px;
  background-color: black;
  border-radius: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &_text {
    color: white;
    text-transform: uppercase;
    font-size: 30px;
    text-align: center;
  }
}

@media screen and (max-width: 615px) {
  .submit-window {
    height: 505px;
    border-radius: 50px;
    &_text {
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 615px) {
  .contact_form-article {
    font-size: 40px !important;
    text-align: center;
  }
}
