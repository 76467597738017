.stand-with-ukraine {
  //

  position: relative;
  z-index: 10;
  padding: 15px;
  display: flex;
  justify-content: center;
  text-align: center;

  &_inner {
    width: 90%;
    max-width: 1200px;
  }

  &_link a {
    font-size: 20px;
  }

  &_wrap {
    display: flex;
    justify-content: center;
    align-items: start;
    margin-top: 20px;
    column-gap: 50px;
    flex-wrap: wrap;
  }

  &_logo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 40px;

    & img {
      width: 15em;
    }
  }


  &_flag {
    position: absolute;
    margin-left: 250px;
    z-index: -1;
    width: 12em !important;

  }

  &_content {
    margin: 20px auto 0;
    max-width: 668px;

    & a{
      color: black;
      font-weight: 700;
      text-decoration: revert;

    }
  }
}

.stand-with-ukraine h1 {
  font-size: 28px;
}

.stand-with-ukraine p {
  font-size: 20px;
}

@media screen and (max-width: 910px) {
  .stand-with-ukraine {
    &_flag {
      margin-left: 220px;
      width: 10em !important;

    }

    & a {
      font-size: 14px;
    }

    &_wrap {
      column-gap: 40px;

      & img {
        //width: 9em;
      }
    }
    &_content {
      margin: 40px auto 0;
    }
  }

  .stand-with-ukraine h1 {
    font-size: 17px;
  }

  .stand-with-ukraine p {
    font-size: 15px;
  }
}

@media screen and (max-width: 615px) {
  .stand-with-ukraine {
    &_flag {
      margin-left: 130px;
      width: 6em !important;

    }
    &_content {
      margin: 10px auto 0;
    }
    & a {
      font-size: 12px;
    }

    &_wrap {
      margin: 0;

      & img {
        width: 8em;
      }
    }

  }
}


