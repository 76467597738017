.privacy {
  background-color: black;
  color: white;
  margin: 0 auto;
  padding: 15px;
  display: flex;
  justify-content: center;
  &_inner {
    width: 90%;
    max-width: 1200px;
  }
  &_link {
    color: #ffa800;
    transition: 0.3s;
    margin-bottom: 30px;
    &:hover {
      color: white;
    }
  }
  &_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    & img {
      margin-left: -30px;
    }
  }
}

.privacy h1 {
  font-size: 28px;
}

.privacy ul li {
  list-style-type: initial;
}

.privacy ul {
  margin-left: 13px;
}
