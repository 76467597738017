
.container {
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.flex {
  display: -webkit-flex;
  display: -msflex;
  display: flex;
}

.flex.full-center {
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}

.flag-icon {
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

.flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em;

  &:before {
    content: "\00a0";
  }
}

.flag-icon-ua {
  background-image: url(./ua.svg);
}

.with-ukraine {
  align-items: center;
  color: #000000;
  font-size: 15px;
  font-weight: 500;
  height: 40px;

  &_flag {
    font-size: 23px;
    margin: 0px 15px 0px 0px;
    top: -2px;
  }

  &_link {
    margin-left: 10px;
    font-size: 10px;
    color: #fff;
    background-color: #ffa800;
    padding: 5px;
    border: 3px solid #ffa800;
    font-weight: 500;
    cursor: pointer;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    text-transform: uppercase;

    &:hover {
      background-color: initial;
      color: #ffa800;
    }
  }
}

@media screen and (min-width: 440px) {
  .with-ukraine br {
    display: none;
  }
}
/*header*/


.header {
  margin: 0 auto;
  width: 100%;
  background-image: url(./background.png);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 10;

  &_nav {
    max-width: 1600px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 25px 100px 10px;
    position: relative;
  }

  &_links {
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_link {
    color: white;
    text-transform: uppercase;
    font-size: 20;
    font-weight: 500;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;

    &:hover {
      color: #fa0;
    }
  }

  &_inner {
    color: white;
    margin: 0 auto;
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 150px 10px;
  }

  &_inner-article {
    text-align: center;
    font-size: 70px;
    font-weight: 500;
  }

  &_inner-text {
    line-height: 30px;
    margin-top: 20px;
    font-size: 25px;
    font-weight: 400;
    text-align: center;
  }

  &_inner-button {
    margin-top: 70px;
    width: 100%;
    max-width: 360px;
    height: 80px;
    text-align: center;
    line-height: 80px;
    background-color: initial;
    border: 3px solid #ffa800;
    font-size: 20px;
    font-weight: 500;
    color: #ffa800;
    cursor: pointer;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    text-transform: uppercase;

    &:hover {
      background-color: #ffa800;
      color: white;
    }
  }
}

.header_link_language {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-img {
    width: 30px;
    transition: 0.2s;
    cursor: pointer;
  }

  &-text {
    color: white;
    margin-right: 5px;
  }

  & a {
    color: white;
    opacity: 0.5;
    margin-left: 2px;
    transition: 0.3s;
  }

  & a.active-link {
    opacity: 1;
  }

  & a:hover {
    color: #ffa800;
    opacity: 1;
  }
}

.link-line:hover {
  color: white !important;
  opacity: 0.5 !important;
}

.header_link_inner {
  padding: 5px;
  padding-top: 9px;
  background-color: initial;
  border: 3px solid #ffa800;
  font-size: 20px;
  font-weight: 500;
  color: #ffa800;
  cursor: pointer;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  text-transform: uppercase;

  &:hover {
    background-color: #ffa800;
    color: white;
  }
}

#header_link_inner-mobile {
  display: none;
}

@media screen and (min-width: 1700px) {
  .header {
    background-image: url(./background-18k.png);
  }
}

@media screen and (min-width: 1900px) {
  .header {
    background-image: url(./background-20k.png);
  }
}

@media screen and (min-width: 2600px) {
  .header {
    background-image: url(./background-28k.png);
  }
}

@media screen and (max-width: 1260px) {
  .header {
    &_links {
      width: 83%;
    }

    &_nav {
      padding-left: 0;
      padding-right: 35px;
    }
  }
  .header_link_language {
    right: 35px;
  }
}

@media screen and (max-width: 440px) {
  .header {
    &_nav {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px 0;
    }

    &_links {
      width: 97%;
    }

    &_logo {
      margin-left: -30px;
    }

    &_inner {
      padding: 70px 10px;
    }

    &_inner-article {
      font-size: 50px;
    }
  }
  .header_link_language {
    right: 10px;
  }
}

.hamburger-menu {
  display: none;
}

@media screen and (max-width: 900px) {
  .hamburger-menu {
    display: block;
  }
  .header_links li {
    display: none;
  }
  .header_links {
    width: 250px;
  }
}

.mobile {
  &_hamburger-menu {
    position: absolute;
    top: -100%;
    left: 0;
    width: 80%;
    background-color: black;
    padding: 10%;
    transition: 0.3s;
  }

  &_hamburger-menu-active {
    top: 0;
  }
}

.hamburger-menu-mobile {
  position: absolute;
  right: 10%;
}

.header_logo-menu {
  margin-left: -30px;
  margin-top: -10px;
  margin-bottom: 30px;
}

.mobile-link {
  font-size: 22px;
  margin-bottom: 10px;
  display: block;
}

/*header end*/

/*affiliates*/

.affiliates {
  background-color: #f5a200;
  width: 100%;
  margin: 0 auto;

  &_top {
    max-width: 1600px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &-img {
      width: 63%;
      margin-top: -140px;
      margin-left: 30px;
    }

    &-inner {
      display: flex;
      flex-direction: column;

      &-article {
        font-size: 70px;
        font-weight: 700;
        color: white;
        text-transform: uppercase;
        margin-bottom: 20px;
        text-align: center;
      }

      &-text {
        font-size: 24px;
        color: white;
        line-height: 27px;
        text-align: right;
      }
    }
  }

  &_bottom {
    padding: 100px 10px 200px;
    margin: 0 auto;
    width: 97%;
    max-width: 800px;
    display: flex;
    justify-content: space-between;

    &-decoration {
      margin-top: 30px;
      width: 10px;
      height: 10px;
      background-color: white;
      border-radius: 50%;
    }

    &-center {
      margin: 0 40px;
      width: 2px;
      background-color: #fdc046;
    }

    &-left {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      &-text {
        text-align: right;
        color: white;
        font-size: 24px;
        line-height: 27px;
      }
    }

    &-right {
      margin-top: 80px;

      &-text {
        text-align: left;
        color: white;
        font-size: 24px;
        line-height: 27px;
      }
    }
  }
}

@media screen and (max-width: 1140px) {
  .affiliates {
    &_bottom {
      padding: 30px 10px;
      box-sizing: border-box;
    }

    &_top {
      flex-direction: column;

      &-img {
        margin-top: -50px;
        margin-bottom: 30px;
      }

      &-inner {
        align-items: center;
      }
    }
  }
}

@media screen and (max-width: 675px) {
  .affiliates {
    &_bottom {
      padding: 30px 0;
      flex-direction: column;

      &-center {
        display: none;
      }

      &-left {
        align-items: center;

        &-text {
          text-align: center;
        }
      }

      &-right {
        margin-top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        &-text {
          text-align: center;
        }
      }
    }
  }
  .affiliates_top-inner-article {
    font-size: 50px;
  }
}

@media screen and (max-width: 400px) {
  .affiliates_top-inner-article {
    font-size: 40px;
  }
}

/*affiliates end*/

/*who*/
.who {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  padding: 100px 0;

  &_inner {
    width: 40%;
    margin-top: 50px;

    &-article {
      font-size: 70px;
    }

    &-text {
      font-size: 20px;
      line-height: 27px;
      color: #858585;
    }
  }

  &_img {
    width: 650px;
    height: 650px;
    margin-top: -180px;
    margin-left: -200px;
    margin-bottom: -180px;
    z-index: 10;
  }
}

@media screen and (max-width: 1340px) {
  .who {
    flex-direction: column;
    align-items: center;

    &_inner {
      width: 97%;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-article {
        text-align: center;
      }

      &-text {
        width: 90%;
        text-align: center;
      }
    }

    &_img {
      width: 650px;
      height: 650px;
      margin-top: 100px;
      margin-left: 0;
      margin-bottom: -180px;
      z-index: 10;
    }
  }
}

@media screen and (max-width: 1140px) {
  .who {
    padding-top: 30px;
  }
}

@media screen and (max-width: 675px) {
  .who {
    &_img {
      width: 90%;
      height: 100%;
      margin-top: 50px;
    }

    &_inner {
      &-article {
        font-size: 50px;
      }

      &-text {
        width: 90%;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 615px) {
  .who {
    padding-top: 0;
  }
}

@media screen and (max-width: 400px) {
  .who {
    &_inner {
      &-article {
        font-size: 40px;
      }
    }
  }
}

/*who end*/

/*advertisers*/
.advertisers {
  margin: 0 auto;
  width: 100%;
  background-image: url(./background3.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 150px 0;

  &_inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1600px;
    align-items: flex-end;
    margin: 0 auto;
    padding: 0 100px;
    box-sizing: border-box;

    &-article {
      font-size: 70px;
      font-weight: 700;
      color: #ffa800;
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    &-text {
      color: white;
      text-align: right;
      font-size: 20px;
      line-height: 27px;
      max-width: 480px;
    }
  }
}

@media screen and (max-width: 675px) {
  .advertisers {
    &_inner {
      padding: 0 20px;
      align-items: center;

      &-article {
        font-size: 50px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .advertisers {
    &_inner {
      &-article {
        font-size: 40px;
      }
    }
  }
}

/*advertisers end*/

/*advertisers-cards*/
.advertisers-cards {
  width: 100%;
  margin: 0 auto;
  max-width: 1600px;
  display: flex;
  justify-content: space-between;
  padding: 100px 50px;
  box-sizing: border-box;
  position: relative;
  z-index: 10;
  margin-top: -150px;

  &_card {
    padding: 45px 40px;
    border-radius: 70px;
    box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1);
    width: 25%;
    background-color: white;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;

    &:hover {
      transform: scale(1.01);
    }

    &-img {
      width: 100%;
      max-width: 138px;
      margin-bottom: 50px;
    }

    &-article {
      font-size: 40px;
      line-height: 53px;
      color: #f5a200;
      text-transform: uppercase;
      font-weight: 600;
    }

    &-text {
      font-size: 20px;
      line-height: 27px;
      color: #858585;
    }
  }
}

.center-card {
  margin-top: -60px;
  height: 100%;
}

@media screen and (max-width: 1280px) {
  .advertisers-cards {
    &_card {
      padding: 35px 20px;
      width: 28%;
      border-radius: 50px;

      &-img {
        width: 30%;
        margin-bottom: 30px;
      }

      &-article {
        font-size: 26px;
        line-height: 30px;
      }

      &-text {
        font-size: 18px;
        line-height: 20px;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .advertisers-cards {
    padding: 100px 10px;

    &_card {
      padding: 25px 15px;
      width: 28%;

      &-img {
        width: 30%;
        margin-bottom: 20px;
      }

      &-article {
        font-size: 20px;
        line-height: 24px;
      }

      &-text {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .advertisers-cards {
    flex-direction: column;
    align-items: center;

    &_card {
      margin-bottom: 20px;
      width: 80%;
      padding: 30px 40px;

      &-img {
        width: 80px;
        margin-bottom: 20px;
      }
    }
  }
  .center-card {
    margin-top: 0;
  }
}

@media screen and (max-width: 675px) {
  .advertisers-cards {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 500px) {
  .advertisers-cards {
    &_card {
      margin-bottom: 20px;
      width: 80%;
      padding: 30px 20px;
    }
  }
}

/*advertisers-cards end*/

/*promote*/
.promote {
  width: 100%;
  margin: 0 auto;
  background-color: #101010;
  padding-top: 100px;
  padding-bottom: 30px;

  &_inner {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;

    &-bottom,
    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &_article {
    color: white;
    font-size: 50px;
    line-height: 67px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 50px;
  }

  &_card {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: 450px;

    &-inner {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
    }

    &-article {
      font-size: 40px;
      line-height: 53px;
      color: #f5a200;
      text-transform: uppercase;
    }

    &-text {
      font-size: 20px;
      line-height: 27px;
      color: #858585;
    }

    &-decoration {
      display: flex;
    }

    &-decoration-circle {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: black;
      border: 1px solid white;
      margin-right: 14px;
      margin-top: 14px;
    }
  }
}

.mrtop {
  margin-top: 30px;
}

.middle {
  margin: 30px auto;
  width: 60%;
  flex-direction: column;
  align-items: flex-end;
}

.bottom {
  margin: 30px auto;
  width: 60%;
  flex-direction: column;
  align-items: flex-start;
}

@media screen and (max-width: 1200px) {
  .promote {
    &_article {
      margin-bottom: 40px;
    }

    &_card {
      &-img {
        width: 60px;
      }

      &-article {
        font-size: 30px;
        line-height: 34px;
      }

      &-text {
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
}

@media screen and (max-width: 890px) {
  .promote {
    &_card {
      &-img {
        width: 50px;
      }

      &-article {
        font-size: 23px;
        line-height: 25px;
      }

      &-text {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .promote_inner-top {
    flex-direction: column;
  }
  .promote_card {
    justify-content: center;
    width: 97%;
  }
  .promote_card-decoration {
    flex-direction: column;
    margin-top: 20px;
  }
  .middle {
    align-items: center;
  }
  .promote_inner-bottom {
    flex-direction: column-reverse;
  }
  .bottom {
    align-items: center;
  }
  .promote_card-inner {
    width: 180px;
  }
  .promote_card-decoration-circle {
    margin-right: 0;
  }
}

@media screen and (max-width: 675px) {
  .promote {
    padding-top: 30px;
  }
}

@media screen and (max-width: 400px) {
  .promote_article {
    font-size: 40px;
  }
}

/*promote end*/

/*contact*/
.contact {
  margin: -10px auto 0;
  width: 100%;
  background-image: url(./background-footer.png);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  &_inner {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-left: 150px;
    box-sizing: border-box;
  }

  &_decoration {
    margin-left: 100px;
    flex-shrink: 0;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #f5a200;
  }

  &_form {
    margin-left: 50px;
    background-color: #f5a200;
    width: 85%;
    border-top-left-radius: 200px;
    padding-top: 200px;
    padding-left: 200px;
    padding-bottom: 200px;
    box-sizing: border-box;

    &-article {
      font-size: 50px;
      font-weight: 600;
      color: white;
      margin-bottom: 20px;
    }

    &-text {
      font-size: 20px;
      font-weight: 500;
      color: white;
      max-width: 300px;
      display: inline-block;
    }
  }
}

@media screen and (min-width: 1700px) {
  .contact {
    background-image: url(./background-footer-18k.png);
  }
}

@media screen and (min-width: 2000px) {
  .contact {
    background-image: url(./background-footer-20k.png);
  }
}

@media screen and (max-width: 1450px) {
  .contact_form {
    padding-left: 100px;
  }
  .contact_inner {
    padding-left: 0;
  }
}

@media screen and (max-width: 1040px) {
  .contact_decoration {
    margin-left: 0;
  }
}

@media screen and (max-width: 940px) {
  .contact_decoration {
    display: none;
  }
  .contact_form {
    width: 100%;
    margin-left: 0;
    padding: 100px;
  }
}

@media screen and (max-width: 767px) {
  .contact_form {
    padding: 30px;
    padding-top: 80px;
    border-top-left-radius: 100px;
  }
}

/*contact end*/

@media screen and (min-width: 2000px) {
  .contact_form-text,
  .promote_card-text,
  .advertisers-cards_card-text,
  .advertisers_inner-text,
  .who_inner-text,
  .affiliates_top-inner-text,
  .affiliates_bottom-right-text,
  .affiliates_bottom-left-text {
    font-size: 30px;
    line-height: 32px;
  }
  .header_inner-text {
    font-size: 32px;
    line-height: 32px;
  }
  .header_inner {
    max-width: 1400px;
  }
  .who_img {
    width: 750px;
    height: 750px;
  }
  .advertisers_inner-text {
    max-width: 580px;
  }
  .advertisers-cards {
    max-width: 1700px;
  }
  .promote_card {
    width: 550px;
  }
  .contact_form-text {
    max-width: 500px;
  }
  .header_link {
    font-size: 24px;
  }
  .affiliates_bottom {
    max-width: 1000px;
  }
}

@media screen and (max-width: 1999px) {
  .contact_form-text,
  .promote_card-text,
  .advertisers-cards_card-text,
  .advertisers_inner-text,
  .who_inner-text,
  .affiliates_top-inner-text,
  .affiliates_bottom-right-text,
  .affiliates_bottom-left-text {
    font-size: 24px;
    line-height: 26px;
  }
  .contact_form-text {
    max-width: 400px;
  }
}

@media screen and (max-width: 1200px) {
  .contact_form-text,
  .promote_card-text,
  .advertisers-cards_card-text,
  .advertisers_inner-text,
  .who_inner-text,
  .affiliates_top-inner-text,
  .affiliates_bottom-right-text,
  .affiliates_bottom-left-text {
    font-size: 20px;
    line-height: 22px;
  }
}

.contact_form-contact {
  margin-top: 30px;
  max-width: 100%;
}

.contact_form-contact-link {
  color: white;
  transition: 0.3s;
}

.contact_form-contact-link:hover {
  color: black;
}

.footer {
  padding: 50px 10px;
  background-color: black;
  margin-top: -1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;

  &_span {
    font-size: 20px;
  }

  &_link {
    color: white;
    text-decoration: none;
    outline: none;
    margin-bottom: 15px;
    transition: 0.3s;

    &:hover {
      color: #f5a200;
    }
  }
}
